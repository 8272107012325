const API_END_POINT = "https://api-backend.bwpapps.com/";
const REACT_APP_BEARER =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjMzYTY0OTJhYTQxYTY5ODY4MGNjNDk3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjM0MDIxZWI2NmRiNmMyMWFkMGU1ZDUyIn0.IWpfEy8GE5ocKARRKA-m3JonDEGv_ymv01XDiABXs7AcZejEKHnyI43SBxkJUiTaiKGjDIDGDbonl4BWeyJrbW8cuIVFqBX9jZolVtvwbuVyt4JlV0PgfgXz88kyrY7917KPRuRXREWfPK1n39gwEx2H1df-dze8sPGnW_jKN8PPuOQdSrtyCCxFo6mnNTbzRKjy8N08expr7xGgU1a7E2CRLQYPx6RrMNJ9x3EsljTBJOmI1nehBZyggyuptqHJroNGzAPxKzf1QP3SMfC6KYhu8-7ku5rvYdDpcbPlg0SshhgbLHXy3SY4z6A84aTiBzG34ZzWUwQ3il-5bnPQSbojm4aWgC5w1W6AUcjTkJRsbAlaYcepSwlULIqaCb2JtfDNbn_pN-994fdn3Blud3n4_JUe2YYvQfDAXGGF9LAw92e4zEcL5oZ3ZwsBasoqa6j4vEzXg8EZttjRiiZy-Y_lo_dKr_CB0mWnDBIe6ZKfTroQpqTzf3Ji1BVrrr5-_g21bizxl0EjTwuUn-H4HrVRxn55dmRcFTRbjEOnJogeAKnMtCz0-HyPexvA7mvc267NDc3Z6rlbvaUNkp9wGdPe1-s63qPC9x68iaRS0GTJHmn451_avPfFCamRzYFmCBaaGXtJPBKet7bgQWWgObOPKdt3Gg_Wl7PfSxO_Wtg";

export const environment = {
  production: true,
  isLive: false,
  API_ENDPOINT: API_END_POINT,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    default: {
      name: "Default",
      API_ENDPOINT: API_END_POINT,
      default: true,
      Bearer: REACT_APP_BEARER,
    },
  },
};
/* Bearer is App token for server */
